import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"

import { logout } from "../redux/actions/currentUserActions"
const IndexPage = ({ user, dispatch }) => {
  useEffect(() => {
    if (user) {
      dispatch(logout())
    }
    navigate("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(IndexPage)
